
// ==============================================

//        NAV FUNCTIONS

// ==============================================

// $(document).ready(function() {
//   var hash = window.location.hash;
//   if (hash) {
//     setTimeout(function(){
//       // console.log(hash)
//       var offset  = 1000;
//       var target  = document.getElementById('project-slider');
//       var targetY = target.getBoundingClientRect().top + window.pageYOffset + offset;

//       // scroll to section
//       autoScrolling = true;
//       $('body,html').animate({ scrollTop : targetY }, 800, 'easeInOutCubic', function(){  autoScrolling = false;  });
//     }, 1000);

//   }
// });


  var autoScrolling = false;    // true when go to section with scroll -> disable scroll watch event

  //        Scroll to section
  // ==============================================

  // Scroll to section
  function scrollToSection(link, offset) {
    var offset  = (typeof offset !== 'undefined') ? offset : 0;
    offset  += (window.innerWidth > 750) ? 0 : 15;
    var target  = document.getElementById(link.hash.substr(1));
    // var targetY = target.offsetTop + offset;
    var targetY = target.getBoundingClientRect().top + window.pageYOffset + offset;

    // scroll to section
    autoScrolling = true;
    $('body,html').animate({ scrollTop : targetY }, 800, 'easeInOutCubic', function(){  autoScrolling = false;  });
  }

  document.getElementById('btn-header--calculator').addEventListener('click', function(e) {
    e.preventDefault();
    scrollToSection(this, -220);
  });
  document.getElementById('btn-header--calendar').addEventListener('click', function(e) {
    e.preventDefault();
    scrollToSection(this, -100);
  });
  document.getElementById('btn-example').addEventListener('click', function(e) {
    e.preventDefault();
    scrollToSection(this, -80);
  });


  //        Pop contact
  // ==============================================

  var pop_contact     = document.getElementById('pop-contact');
  var btn_pop_contact = document.getElementById('btn-pop-contact');

  // Close pop contact
  function closePopContact(e) {
    e.preventDefault();
    this.classList.remove('is-open');
    pop_contact.classList.remove('is-open');
    this.removeEventListener('click', closePopContact);
    this.addEventListener('click', openPopContact);
  }

  // Open pop contact
  function openPopContact(e) {
    e.preventDefault();
    this.classList.add('is-open');
    pop_contact.classList.add('is-open');
    this.removeEventListener('click', openPopContact);
    this.addEventListener('click', closePopContact);
  }

  btn_pop_contact.addEventListener('click', openPopContact);



  //        Collapse header when scroll down
  // ==============================================

  var lastScroll       = 0;
  var header           = document.getElementById('header');
  var slick_prev       = document.getElementById('slick-prev');
  var slick_next       = document.getElementById('slick-next');
  var project_slider   = document.getElementById('project-slider');
  var home_visu__video = document.getElementById('home-visu__video');
  var cta              = document.getElementById('cta');


  function toggleHeaderState() {
    var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;

    // activate sticky header
    var mainY = document.querySelector('main').offsetTop;
    (scrollTop > mainY) ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');

    // toggle header state
    var headerSmallLimit = (window.innerWidth > 750) ? 90 : 300;
    // var headerSmallLimit = (window.innerWidth > 750) ? 190 : 400;
    ( scrollTop > headerSmallLimit) ? header.classList.add('is-small') : header.classList.remove('is-small');

    // show / hide pop-contact btn
    ( scrollTop > 190) ? pop_contact.classList.add('is-active') : pop_contact.classList.remove('is-active');

    // show / hide btn next / prev for project slider
    if (window.innerWidth > 640 && !autoScrolling) {
      var slider_coords = getCoords(project_slider);
      // console.log('>>', slider_coords.top, scrollTop, 'b', slider_coords.bottom, window.innerHeight);
      if ( scrollTop > slider_coords.top - window.innerHeight/2 && scrollTop < slider_coords.bottom - window.innerHeight/2) {
        slick_prev.classList.add('is-active');
        slick_next.classList.add('is-active');
      } else {
        slick_prev.classList.remove('is-active');
        slick_next.classList.remove('is-active');
      }
    }

    // play / pause home video
    // var cta_y = getCoords(cta).top - 100;
    //   // console.log('>>', cta_y, scrollTop, window.innerHeight, home_visu__video.paused);
    // if ( scrollTop > cta_y) {
    //   if (!home_visu__video.paused) home_visu__video.pause();
    // } else {
    //   if (home_visu__video.paused) home_visu__video.play();
    // }

    lastScroll = scrollTop;
  }
  window.addEventListener('scroll', toggleHeaderState);
  window.addEventListener('resize', toggleHeaderState);
  toggleHeaderState();    // init first


  // get document coordinates of the element relative to the document
  function getCoords(el) {
    var rect = el.getBoundingClientRect();

    return {
      top   : rect.top + window.pageYOffset,
      left  : rect.left + window.pageXOffset,
      bottom: rect.bottom + window.pageYOffset
    };
  }



  //        Video player
  // ==============================================

  function launchYouTubeVideo(e) {
    this.classList.add('is-hidden');
    
    var player = this.parentNode.querySelector('iframe');
    
    // post our command to the iframe.
    if (player != undefined) {
        var command = {
            "event": "command",
            "func": "playVideo"
        };
        player.contentWindow.postMessage(JSON.stringify(command), "*");
    }
  }   

  document.querySelectorAll('[data-btn-play]').forEach( function( el ) {
    el.addEventListener('click', launchYouTubeVideo);
  });



  //        AOS animation
  // ==============================================


  // !!! hack : delay to init AOS after Slick init
  setTimeout(function(){
    AOS.init({
      duration: 600,
      once: true,
      easing: 'ease-out-quart',
    });
   }, 1000
  );



  //        Config Fancybox lightbox
  // ==============================================
  $('[data-fancybox]').fancybox({
    buttons : [
      'close'
    ],

    btnTpl: {
      // This small close button will be appended to your html/inline/ajax content by default,
      // if "smallBtn" option is not set to false
      smallBtn:
        '<button type="button" data-fancybox-close class="fancybox-button fancybox-close-small">' +
        '<svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg"><g fill="#000" fill-rule="evenodd"><path d="M14.407.265l3.328 3.328L3.593 17.735.265 14.407z"/><path d="M17.735 14.407l-3.328 3.328L.265 3.593 3.593.265z"/></g></svg>' +
        "</button>"
        // '<button type="button" data-fancybox-close class="fancybox-button fancybox-close-small">' +
        // '<svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 24 24"><path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"/></svg>' +
        // "</button>"
    },
  });



// });
