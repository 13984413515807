// ==============================================

//        FORM NEWSLETTTER

// ==============================================


$(document).ready(function(){

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  var firebaseConfig = {
    apiKey: "AIzaSyAqueuJOn3A7xAaHSql1uIZPBq33sbMoa8",
    authDomain: "icm-database.firebaseapp.com",
    projectId: "icm-database",
    storageBucket: "icm-database.appspot.com",
    messagingSenderId: "391500315422",
    appId: "1:391500315422:web:6e43ce827bd14d3be3a70f"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  // Initialize Firestore
  var db = firebase.firestore();


  //Create var
  var form        = document.getElementById('form-newsletter');
  var feedback    = form.querySelector('.form-newsletter__feedback');
  var sending_msg = 'Enregistrement en cours';
  var confirm_msg = '<strong>Merci, vous êtes maintentant inscrit à notre newsletter</strong>';
  var error_msg   = 'Erreur d\'enregistrement';

  var docId;
  var submit = false

  // Listen for form lead submit
  form.addEventListener('submit', submitLead);

  // Submit lead
  function submitLead(e){
    e.preventDefault();

    if (!getInputVal('civility')) {
      feedback.textContent = 'Champ civilité invalide';
      return;
    }
    if (!emailIsValid(getInputVal('email'))) {
      feedback.textContent = 'Email invalide';
      return;
    }
    feedback.textContent = sending_msg;

    // Save lead
    if (!submit) saveLead();
    submit = true;
    feedback.innerHTML = confirm_msg;
  }

  // Function to get form value
  function getInputVal(id){
    return document.getElementById(id).value;
  }

  function emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  // Save lead to firebase
  function saveLead(){
    var civility  = getInputVal('civility');
    var firstname = getInputVal('firstname');
    var lastname  = getInputVal('lastname');
    var email     = getInputVal('email');
    // console.log('fields:', civility, firstname, lastname, email);
    
    db.collection("subscriptions").add({
      email    : email,
      firstname: firstname,
      lastname : lastname,
      civility : civility,
      origine  : "ifi-2021",
    })
    .then(function(docRef) {
        docId = docRef.id;
        //
        document.getElementById('civility').value = '';
        document.getElementById('firstname').value = '';
        document.getElementById('lastname').value = '';
        document.getElementById('email').value = '';
    })
    .catch(function(error) {
        console.error("Error adding document: ", error);
        feedback.textContent = error_msg;
    });
  }


});
